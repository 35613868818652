@font-face {
  font-family: 'ProximaNovaRegular';
  src: url('../font/Proxima-Nova-Regular.ttf');
}

@font-face {
  font-family: 'ProximaNovaSemiBold';
  src: url('../font/Proxima-Nova-Semibold.ttf');
}

img {
  width: 100%;
  height: 100%;
}

.bg-dark {
  background-color: #042740;
  height: 100vh;
}

.bg-light {
  background-color: #fff;
  height: 100vh;
}

.calleyo-login-head {
  padding: 10% 10%;
}

.calleyo-login-head h1 {
  font-size: 66px;
  font-family: 'ProximaNovaRegular';
  font-weight: 500;
  margin: 0px;
  color: #042740;
}

.calleyo-login-head h2 {
  font-size: 48px;
  font-family: 'ProximaNovaSemiBold';
  font-weight: 600;
  margin: 0px;
  color: #042740;
}

.calleyo-login-logo {
  padding: 0% 10%;
  padding-top: 15%;
}

.calleyo-login-logo img {
  width: 200px
}

.calleyo-login-form {
  width: 320px;
  background-color: #fff;
  border-radius: 20px;
  padding: 25px 10%;
  position: relative;
  right: 20%;
  top: 20%;
  box-shadow: -5px 5px 5px gray;
}

.input-box {
  margin-top: 15px;
  text-align: center;
}

.login-form-logo {
  width: 200px;
}

.calleyo-login-form h4 {
  font-size: 24px;
  font-family: 'ProximaNovaSemiBold';
  font-weight: 600;
  margin: 0px;
  color: #042740;
}

.calleyo-login-form p {
  font-size: 16px;
  font-family: 'ProximaNovaRegular';
  font-weight: 400;
  margin: 0px;
  color: #042740;
}

.input-box {
  width: 100%;
}

.text-input {
  width: 100%;
}

.crm-submit-btn {
  background-color: #042740 !important;
  color: #fff;
  width: 100%;
  font-size: 16px;
  font-family: 'ProximaNovaRegular';
}

.w100 {
  width: 100%;
}

.calleyo-bar {
  background-color: #042740 !important;
}

.header-icon {
  position: absolute;
  right: 10px;
}

#page-wrapper {
  padding: 25px 25px 0px 85px;
}

.table-head-cell {
  background-color: #042740 !important;
  color: #fff !important;
}

.history-tab {
  width: 33%;
}

.history-tree {
  height: 320px;
  overflow-y: scroll;
}

.history-tree::-webkit-scrollbar {
  display: none;
}

.lead-details {
  height: 300px;
  background-color: #042740;
  width: 90%;
  border-radius: 20px;
  padding: 15px;
  color: #f2f2f2;
}

.lead-details h2 {
  font-family: 'ProximaNovaRegular';
  margin: 0px;
}

.lead-details h4 {
  font-family: 'ProximaNovaRegular';
  margin: 0px;
}

.lead-desc {
  /* margin-top: 25px; */
}

.lead-desc-box {
  margin-top: 25px;
}

.lead-desc-head {
  font-family: 'ProximaNovaRegular' !important;
  font-size: 18px;
  color: gray;
}

.lead-desc-info {
  font-family: 'ProximaNovaRegular' !important;
  font-size: 14px !important;
  color: #f2f2f2;
}

.lead-history-box {
  background-color: #f2f2f2;
  padding: 8px 5px;
}

.prox-reg {
  font-family: 'ProximaNovaRegular' !important;
}

.gray {
  color: gray !important;
}

.small-tag {
  font-size: 12px !important;
}

.font-normal {
  font-size: 16px !important;
}

.font-sm {
  font-size: 14px !important;
}

.bold {
  font-weight: bold !important;
}


@media screen and (max-width: 967px) {

  .bg-dark {
    height: auto;
    padding: 25px 8%;
  }
  .bg-light{
    height: auto;
  }

  .calleyo-login-head {
    padding: 25px 5%;
  }

  .calleyo-login-form {
    margin: auto;
    top: 0px;
    right: 0;
  }
  .calleyo-login-logo {
    display: none;
  }

}

@media screen and (max-width: 576px) {
  .bg-dark {
    height: auto;
  }
  .bg-light{
    height: auto;
  }

  .calleyo-login-head {
    padding: 10% 10%;
  }

  .calleyo-login-form {
    width: 85%;
    margin: auto;
    top: 0px;
    right: 0;
  }
  .calleyo-login-head h1{
    font-size: 36px;
  }
  .calleyo-login-head h2{
    font-size: 28px;
  }

}


.loader-grid{
  position: absolute;
  top: 40%;
  left: 45%;
  z-index: 99999999;
  background-color: #042740;
  padding: 50px;
  opacity: 0.4;
}
.sub-menu{
  padding: 0px 30px;
}
.sub-menu-item{
  cursor: pointer;
  font-size: 14px !important;
  padding: 4px;
  color:darkgray;
}
.sub-menu-item:hover{
  color: #042740 !important;
}


.comment-head{
  font-family: 'ProximaNovaRegular' !important;
}